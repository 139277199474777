export default {
    getInformationgITXcaretRuteo(state) {
        return state.informationgITXcaretRuteo
    },
    getResumengITXcaretRuteo(state) {
        return state.resumengITXcaretRuteo
    },
    getDataSteps(state) {
        return state.dataSteps
    },
    getOpcionesgITXcaretRuteo(state) {
        return state.opcionesgITXcaretRuteo
    },
    getWarehousesgITXcaretRuteo(state) {
        return state.warehousesgITXcaretRuteo
    }
}