export default {
    async setListaRatreoUsuarios(state, payload) {
        let indexCaptura = 1
        let indexRegistro = 1
        state.rastreoUsuarios = payload.map(rastreoUsuario => {
            const data = { ...rastreoUsuario, indexRegistro: indexRegistro++ }
            if (rastreoUsuario.evento === 'Captura') {
                data.indexCaptura = indexCaptura++
            }
            return data
        })
    },
    async setListaUsuariosByRol(state, payload) {
        state.listaUsuariosRol = payload
    },
    setLoadingListaUsuariosRol(state, payload) {
        state.loadingListaUsuariosRol = payload
    },
    setLoadingXlsListaUsuariosRol(state, payload) {
        state.loadingXlsListaUsuariosRol = payload
    },
}