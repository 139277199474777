import gitCoreApi from '@/api/gitCoreApi'
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js' 

/* eslint-disable */
export default {
    async listarCapturas(context, payload) {
        try{
            const { status, data } = await gitCoreApi.post(`galery/photos`,{
                'dates': payload.fechas,
                'usuario':payload.usuario,
                'actividades': payload.actividades
            },
            {
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
            
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }
            context.commit('setListaCapturas', data) 
            
        } catch (error) {
            console.error(error)
            throw error
        } 
    },

    async listarUsuariosCompania(context, payload) {
        try{
            const { status, data } = await gitCoreApi.post(`configuration/user/get_list_users`, {},{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
            
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }

            context.commit('setListaUsuariosByCompania', data)

        } catch (error) {
            console.error(error)
            throw error
        } 
    },

    async listarActividades(context, payload) {
        try{
            const { status, data } = await gitCoreApi.post(`galery/activities`, {},{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
            
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }

            context.commit('setListaActividadesByCompania', data)

        } catch (error) {
            console.error(error)
            throw error
        } 
    },
    async obtenerFotos(context, payload) {
        try{
            const { status, data } = await gitCoreApi.post(`galery/get_pictures`,{
                'id_capture': payload.id_captura
            },
            {
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
            
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }
            context.commit('setListaPictures', data) 
            
        } catch (error) {
            console.error(error)
            throw error
        } 
    },
    async descargarImagenes(context, payload){
        try {
            const { status, data } = await gitCoreApi.post(`galery/download_image_query`,{
                'id_capture': payload.id_capturas,
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed to logout, try later')
            }
            const { dataBase64, fileName } = data
            descargaBaseSesentaycuatro(dataBase64, fileName, 'zip')
        } catch (error) {
            throw new Error(error)
        }
    },
}