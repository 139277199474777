import gitCoreApi from '@/api/gitCoreApi' 

/* eslint-disable */
export default {

    async obtenerAllInformacionDashboard(context, payload) {
        context.commit('setLoading',true)
        await context.dispatch('obtenerInformaciongITXcaretRuteo', payload)
        await context.dispatch('obtenerResumengITXcaretRuteo', payload)
        // await context.dispatch('obtenerConteoVehiculos', payload)
        // await context.dispatch('dataStepsgITXcaretRuteo', payload)
        context.commit('setLoading',false)
    },

    async obtenerInformaciongITXcaretRuteo(context, payload) {
        try {
            const { status, data } = await gitCoreApi.get('xcaret/route/information', {
                params: {
                    date: payload.fecha
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            context.commit('setInformationDashboard', data)
        } catch (error) {
            throw new Error(error)
        }
    },

    async obtenerResumengITXcaretRuteo(context, payload) {
        try {
            const { status, data } = await gitCoreApi.get('xcaret/route/summary', {
                params: {
                    date: payload.fecha
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            context.commit('setResumenDashboard', data)

        } catch (error) {
            throw new Error(error)
        }
    },

    async obtenerConteoVehiculos(context, payload) {
        try {
            const { status, data } = await gitCoreApi.get('xcaret/vehicle_counter', {
                params: {
                    date: payload.fecha
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            // {
            //     tipoVehiculo: fk,
            //     total: 578
            // }
            context.commit('setConteoVehiculos', data)
            console.log("🚀 ~ file: actions.js:76 ~ obtenerConteoVehiculos ~ data:", data)

        } catch (error) {
            throw new Error(error)
        }
    },

    
}