/* eslint-disable */
import gitCoreApi from '@/api/gitCoreApi'
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'
import { format } from 'date-fns'

export default {
    async catalogoTareaAppListado(context, payload) {
        try {
            context.commit('setLoading', true)
            const url = `/configuration/tasks/list?nombre=${payload.nombre}&acceso=${payload.tipoAcceso}&date=${payload.fecha}`
            const { status, data } = await gitCoreApi.get(url, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }
            context.commit('setListaTareasApp', data.data)

        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }
    },

    async catalogoTareaDetalleListado(context, payload) {
        try {
            if (!payload.ocultarLoading) context.commit('setLoading', true)
            
            const { status, data } = await gitCoreApi.get(`/configuration/tasks/detailList?idTask=${payload.id}`, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }
            context.commit('setListaTareasDetails', data)

        } catch (error) {
            console.error(error)
            throw error
        } finally {
            if (!payload.ocultarLoading) context.commit('setLoading', false)
        }
    },
    async tasksCambiarEstatus(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.get(`/configuration/tasks/changeStatus?idTask=${payload.id}&estatus=${payload.estatus}`, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.estatus) {
                case 0:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La vehiculo se ha desactivado correctamente'
                    }
                    break
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La vehiculo se ha activado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'La vehiculo se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async insertarNuevaTarea(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('/configuration/tasks/set_tasks',
                {
                    acceso: payload.acceso,
                    descripcion: payload.descripcion,
                    estatus: payload.estatus,
                    fkCompany: payload.fkCompany,
                    id: payload.id,
                    nombre: payload.nombre,
                    detalles: payload.detalles

                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar la nueva Tarea')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.isError) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.message
                }
            } else {
                mensaje = {
                    tipo: 'success',
                    titulo: data.message
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },

    async editarTareaApp(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('/configuration/tasks/update_tasks',
                {
                    acceso: payload.acceso,
                    descripcion: payload.descripcion,
                    estatus: payload.estatus,
                    id: payload.id,
                    nombre: payload.nombre,
                    detalles: payload.detalles

                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar la nueva Tarea')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.isError) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'warn',
                    titulo: data.message
                }
            } else {
                mensaje = {
                    tipo: 'success',
                    titulo: data.message
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },

    async creaExcelTareasApp(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('configuration/tasks/export_xls_tasks', {
                getEmpty: payload.getEmpty
            } ,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                throw new Error(data.message || 'Error al descargar archivo')
            }
    
            const nombreArchivo = payload.getEmpty
                ? 'Template_vehículos'
                : `Exporta_vehículos_${format(new Date(), 'dd-MM-yyyy')}`

            descargaBaseSesentaycuatro(data, nombreArchivo, 'xlsx')
        } catch (error) {
            throw new Error(error)
        }
    },

    async importTareasApp(context, payload) {
        try {
            context.commit('setLoading', true)
            const solicitud = new FormData()
            solicitud.append('file', payload.file)

            const { status, data } = await gitCoreApi.post('/configuration/tasks/import_xls_tasks', solicitud, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = {
                tipo: data.isError ? 'error' : 'success',
                titulo: data.message,
                tiempo: data.isError ? 10000 : 1500
            }

            if (data.isError) context.commit('setLoading', false)

            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async catalogoTareaUsuariosListado(context, payload) {
        try {
            const { status, data } = await gitCoreApi.get(`configuration/tasks/users_list?idTask=${payload.idTarea}`,
         {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }
            context.commit('setListaTareasUsuarios', data)
    
        } catch (error) {
            throw new Error(error)
        }
    },
    async actualizarTareaUsuarios(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('/configuration/tasks/set_task_users',
                {
                    listUsers: payload.listUsers,
                    idTask: payload.idTarea

                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar la nueva Tarea')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.commit('setLoading', false)
                context.dispatch('catalogoTareaUsuariosListado', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    async catalogoTareaActividadesListado(context, payload) {
        try {
            const { status, data } = await gitCoreApi.get(`configuration/tasks/activities_list?idTask=${payload.idTarea}`,
         {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }
            context.commit('setListaTareasActividades', data)
    
        } catch (error) {
            throw new Error(error)
        }
    },
    async actualizarTareaActividades(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('/configuration/tasks/set_task_activities',
                {
                    listActivities: payload.listActivities,
                    idTask: payload.idTarea

                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar la nueva Tarea')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.commit('setLoading', false)
                context.dispatch('catalogoTareaActividadesListado', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    //
    async catalogoTareaPuntosListado(context, payload) {
        try {
            const { status, data } = await gitCoreApi.get(`configuration/tasks/points_list?idTask=${payload.idTarea}`,
         {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }
            context.commit('setListaTareasPuntos', data)
    
        } catch (error) {
            throw new Error(error)
        }
    },
    async catalogoTareaPuntosListadoPaginado(context, payload) {
        context.commit('setLoading', true)
        try {
            const { data } = await gitCoreApi.get(`configuration/tasks/points_list_paginate`,
            {
                params: {
                    idTask: payload.idTarea,
                    searchTerm: payload.busqueda,
                    page: payload.pagina,
                    sortBy: payload.columna,
                    sortOrder: payload.ordenamiento,
                    pageSize: payload.tamanoPagina,
                    assigned: payload.asignado,
                    dateInit: payload.fechaInicio,
                    dateEnd: payload.fechaFin,
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            context.commit('setLoading', false)
            return data
    
        } catch (error) {
            context.commit('setLoading', false)
            throw new Error(error)
        }
    },
    async asignarTareaPunto(context, payload) {
        try {
            context.commit('setLoading', true)
            const { data } = await gitCoreApi.post('/configuration/tasks/set_task_points',
                {
                    listPoints: payload.listPoints,
                    idTask: payload.idTarea

                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            const mensaje = {
                tipo: data.isError ? 'error' : 'success',
                titulo: data.message
            }

            return mensaje
        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    async actualizarTareaPuntos(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('/configuration/tasks/set_task_points',
                {
                    listPoints: payload.listPoints,
                    idTask: payload.idTarea

                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar la nueva Tarea')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    async getCompaniaDetallesPorIds(context, payload) {
        try {
            if (payload.ids.length === 0) return []

            const { data } = await gitCoreApi.post('/company_details', {
                ids: payload.ids
            }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json',
                },
            })

            return data
        } catch (error) {
            console.error(error)
            throw error
        }
    }
}