/*eslint-disable*/
import gitCoreApi from '@/api/gitCoreApi'
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'

export default {

    /**
     * Devuelve las lista de puntos
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async listarPuntos(context, payload) {
        try{

            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.get(`configuration/points`, {
                params: {
                    "code": payload.codigo,
                    "assigned": payload.asignado,
                    "accessType": payload.tipoAcceso,
                    "date": payload.fecha,
                    "idProcess": payload.idProceso,
                    "advnacedFilters": payload.filtrosAvanzados
                },
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
             // Se maneja el caso de error en la petición
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado de puntos')
                throw error
            }
            context.commit('setListarPuntos', data) 
            
        } catch (error) {
            console.error(error)
        } finally {
            context.commit('setLoading', false)
        }
    },

//-------------------------------------------------------------------------------------------------------------------

    async listarDetalles(context, payload) {
        try{
            const { status, data } = await gitCoreApi.get(`configuration/points/details`,{
                params: {
                    "idPoint": payload.id,
                    "idProcess": payload.idProceso,
                    "groupDetails": payload.groupDetails
                },
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
             // Se maneja el caso de error en la petición
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado de detalles')
                throw error
            }

            context.commit('setListarDetalles', data) 
            
        } catch (error) {
            console.error(error)
        } finally {
            context.commit('setLoading', false)
        }
    },

//-------------------------------------------------------------------------------------------------------------------

    /**
     * Devuelve las lista de puntos
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async listarDetallesCompaniaPuntos(context, payload) {

        try{

            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post(`configuration/points/get_details_by_company`,{},{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
            // Se maneja el caso de error en la petición
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado de puntos')
                throw error
            }
            context.commit('setListarDetallesCompania', data) 
            
        } catch (error) {
            console.error(error)
        } finally {
            context.commit('setLoading', false)
        }
    },

//-------------------------------------------------------------------------------------------------------------------

    async cambiarEstatus(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('configuration/points/update_point_for_points',{ 
                    "idpunto": payload.idPunto,
                    "estatus": payload.estatus,
                    "advnacedFilters": payload.filtrosAvanzados
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                throw error
            }
            let mensaje = null
            switch (payload.estatus) {
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El punto se ha activado correctamente'
                    }
                    break
                case 2:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El punto se ha desactivado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'El punto se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('listarPuntos', payload.busquedaPunto)
            }
            return mensaje

        } catch (error) {
            console.error(error)
        } finally {
            context.commit('setLoading', false)
        }
    },

//-------------------------------------------------------------------------------------------------------------------

    async cambiarEstatusMovil(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('configuration/points/update_details_company',{ 
                    "idpunto": payload.id,
                    "idDetail": payload.idcompania_detalles,
                    "visible": payload.visible
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                throw error
            }
            let mensaje = null
            switch (payload.visible) {
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'Se ha activado correctamente'
                    }
                    break
                case 2:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'Se ha desactivado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'El el detalle a eliminado se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('listarDetalles', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
        } finally {
            context.commit('setLoading', false)
        }
    },
    async cambiarEstatusWeb(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('configuration/points/update_details_company',{ 
                    "idpunto": payload.id,
                    "idDetail": payload.idcompania_detalles,
                    "visibleWeb": payload.visibleWeb
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                throw error
            }
            let mensaje = null
            switch (payload.visibleWeb) {
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'Se ha activado correctamente'
                    }
                    break
                case 2:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'Se ha desactivado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'El el detalle a eliminado se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('listarDetalles', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
        } finally {
            context.commit('setLoading', false)
        }
    },

//-------------------------------------------------------------------------------------------------------------------

    async cambiarEstatusDetalle(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('configuration/points/update_details_point',{ 
                    "idpunto": payload.id,
                    "idPoint": payload.idpuntos_detalles,
                    "status": payload.id_status
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                throw error
            }
            let mensaje = null
            switch (payload.id_status) {
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'Se ha activado correctamente'
                    }
                    break
                case 2:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'Se ha desactivado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'El el detalle a eliminado se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('listarDetalles', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
        } finally {
            context.commit('setLoading', false)
        }
    },

//-------------------------------------------------------------------------------------------------------------------

    /**
     * Edita Las Coordenadas De Un Punto
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
        async editarCoordenadasPunto(context, payload) {
            try {
                context.commit('setLoading', true)
                const { status, data } = await gitCoreApi.post('configuration/points/update_point',
                    {
                        "idpunto" : payload.id,
                        "x" : payload.latitud,
                        "y" : payload.longitud,
                        "code" : payload.codigo,
                        "description" : payload.descripcion,
                        "advnacedFilters": payload.filtrosAvanzados
                    }, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                })
    
                if (status !== 200) {
                    console.error(data)
                    const error = new Error(data.message || 'Error al editar las coordenadas del punto.')
                    context.commit('setLoading', false)
                    throw error
                }
    
                let mensaje = null
    
                if (data.errores > 0) {
                    context.commit('setLoading', false)
                    mensaje = {
                        tipo: 'error',
                        titulo: data.mensaje
                    }
                } else {
                    context.dispatch('listarRoles', payload)
                    mensaje = {
                        tipo: 'success',
                        titulo: data.mensaje
                    }
                }
                return mensaje
    
            } catch (error) {
                context.commit('setLoading', false)
                console.error(error)
                throw error
            }
        },

//-------------------------------------------------------------------------------------------------------------------

    /**
     * Edita un Punto
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async editarDetallePunto(context, payload) {
        try {
            console.log(payload)
            let params = {
                'idpunto': payload.id,
                'details': payload.detalle
            }
            if(payload.idProceso) params = { ...params, 'idProcess': payload.idProceso }

            const { status, data } = await gitCoreApi.post('configuration/points/updateAllDetailsPoint', params, {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al editar el detalle del punto.')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.message
                }
            } else {
                context.dispatch('listarDetalles', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.message
                }
            }
            return mensaje

        } catch (error) {
            console.error(error)
            throw error
        }
    },

//-------------------------------------------------------------------------------------------------------------------

    /**
     * Inserta un nuevo punto
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async insertarPunto(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('configuration/points/insert_point',
                {
                    "idpoint" : payload.id,
                    "x" : payload.latitud,
                    "y" : payload.longitud,
                    "code" : payload.codigo,
                    "description" : payload.descripcion,
                    "advnacedFilters": payload.filtrosAvanzados
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al insertar punto')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('listarPuntos', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },

//---------------------------------------------------------------------------------------------------------------

    async editarAccesoPunto(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('configuration/points/update_acces_point',
                {
                    "idpunto": payload.id,
                    "accessType":payload.acceso
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al editar acceso')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('listarPuntos', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },

//---------------------------------------------------------------------------------------------------------------

    async contadorCuentas(context, payload) {
        try{

            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post(`configuration/points/count_accounts`,{},{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
             // Se maneja el caso de error en la petición
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el conteo de cuentas')
                throw error
            }
            context.commit('setContadorCuentas', data) 
            
        } catch (error) {
            console.error(error)
        } finally {
            context.commit('setLoading', false)
        }
    },

//---------------------------------------------------------------------------------------------------------------

    async obtenerPermisosConfGral(context, payload) {
        try{

            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post(`configuration/gitcore/get_permissions_by_company_confgral`,{},{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
             // Se maneja el caso de error en la petición
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener los permisos')
                throw error
            }
            context.commit('setPermisosConfGral', data) 
            
        } catch (error) {
            console.error(error)
        } finally {
            context.commit('setLoading', false)
        }
    },

    async tiposVehiculosActualizado(context, payload) {
        try {
            const { status } = await gitCoreApi.post(`configuration/points/type_vehicle`, 
            {
                type_vehicle: payload.tiposVehiculos
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
        
        } catch (error) {
            throw new Error(error)
        }
    },

    async listarTiposVehiculo(context, payload) {
        try {
            const { status, data } = await gitCoreApi.get(`configuration/points/list_type_vehicle`, {
                params: {
                    id: payload.id
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
        
            context.commit('setListaTiposVehiculos', data) 
            
        } catch (error) {
            throw new Error(error)
        }
    },

    async buscaGeolocalizacion(context, payload) {
        try {
            const solicitud = new FormData()
            solicitud.append('file', payload.archivo)
            const { status, data } = await gitCoreApi.post(`configuration/points/geolocalizacion`,solicitud,
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            if (data?.isError) {
                context.commit('setLoading', false)
                return data
            } else {
                descargaBaseSesentaycuatro(data, 'Geolocalización', 'xlsx' )
                context.commit('setLoading', false)
                return data
            }
        } catch (error) {
            context.commit('setLoading', false)
            throw new Error(error)
        }
    },
    async obtenPlantillaGeolocalizacion(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('configuration/points/geolocalizacion_layout',
            {},
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener la plantilla')
                throw error
            }
    
            descargaBaseSesentaycuatro(data, 'Layout geolocalización', 'xlsx' )
        } catch (error) {
            throw new Error(error)
        }
    }
}
