import { format, differenceInSeconds, addMinutes } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { Loader } from "@googlemaps/js-api-loader"
import { config } from '@/config/envConfig'

import store from '../store'

/**
 * Regresa del store si el tipo de zona es geocerca
 * @returns {Boolean}
 */
function esTipoZonaGeocerca() {
    const tipoZona = store.getters.permisoConfiguracionGral('tipo_zona')
    return tipoZona?.valor === 'geocerca'
}

const googleMapsLoader = new Loader({
    apiKey: config.googleMapsKey,
    libraries: ["geometry", "places"]
})
/**
 * Permite obtener el porsentaje total de la carga
 * @param {int} tota 
 * @param {int} carga 
 * @returns {int} porcentaje
 */
const mostrarPorcentaje = (tota, carga) =>{

    if (tota == 0 || carga == 0 ) {
        return 0
    }
    
    return Math.round((carga /tota)*100)
}

/**
 * Permite convertir los segundos a su formato en hora (hh:mm hrs.)
 * @param {int} segundos
 * @returns {String} - Hora en formato (hh:mm hrs.)
 */
const mostrarTiempo = (segundos) => {
    let hora = Math.floor(segundos / 3600)
    hora = (hora < 10)? '0' + hora : hora
    let minuto = Math.floor((segundos / 60) % 60)
    minuto = (minuto < 10)? '0' + minuto : minuto
    return hora + ':' + minuto + ' hrs.'
}

/** 
 * Permite convertir los metros a su formato de kilometros o metros (15.5 km., 15.5 mts.)
 * @param {int} metros 
 * @returns {String} - Distancia en kilometros o metros (15.5 km., 15.5 mts.)
*/
const mostrarDistancia = (metros) =>{
    let textoMetros = ""

    if  (metros > 999) {
        textoMetros = (metros/1000).toFixed(3) + ' km.'
    } else {
        textoMetros = metros + ' m.'
    }

    return textoMetros
}

const agregaMinutos = (hora,minutos) => {
    const horaSplit = hora.split(":")
    const fecha = new Date()
    fecha.setHours(horaSplit[0])
    fecha.setMinutes(horaSplit[1])
    fecha.setSeconds(0)
    return format(addMinutes(fecha, minutos), "HH:mm")
}

const compararHoras = (horaInicio, horaFin) => {
    const horaInicioSplit = horaInicio.split(":")
    const horaFinSplit = horaFin.split(":")
    const fechaInicio = new Date()
    const fechaFin = new Date()
    fechaInicio.setHours(horaInicioSplit[0])
    fechaInicio.setMinutes(horaInicioSplit[1])
    fechaInicio.setSeconds(0)
    fechaFin.setHours(horaFinSplit[0])
    fechaFin.setMinutes(horaFinSplit[1])
    fechaFin.setSeconds(0)
    return differenceInSeconds(fechaInicio, fechaFin)
}

const formatearDistanciaPlaneada = (distancia) => {
    if (!distancia) return 0
    return (parseInt(distancia) / 1000).toFixed(2) || 0
}

const calcularKilometrosEjecutados = (idTarea, viajes, fecha = format(new Date(), 'yyyy-MM-dd')) => {
    const viajesVehiculo = viajes.find(vehiculo => vehiculo.fk_tarea === idTarea)
    if (!viajesVehiculo) return 0
    const datosViajes = pluck(viajesVehiculo.trips, 'attributes').map(trip => JSON.parse(trip))
    const viajesFiltradosFecha = datosViajes.filter(viaje => formatInTimeZone(viaje.start, 'America/Mexico_City', 'yyyy-MM-dd') === fecha)
    if (!viajesFiltradosFecha.length > 0) return 0
    const soloKilometros = pluck(viajesFiltradosFecha, 'distance')
    const kilometrosEjecutados = soloKilometros.reduce((acc, currentVal) => acc + currentVal, 0)
    return typeof kilometrosEjecutados === 'number' ? Math.round(kilometrosEjecutados) : 0
}

/**
 * Permite eliminar un elemento de un array
 * @param {array} - Array de objetos 
 * @param {obj} - elemento a eliminar
*/
const removerElementoArray = ( arr, item ) => {
    let i = arr.indexOf( item )

    if ( i !== -1 ) {
        arr.splice( i, 1 )
    }
}

const obtenerFechaDeHora = (hora) => {
    let fecha = new Date()
    fecha.setHours(hora.split(':')[0])
    fecha.setMinutes(hora.split(':')[1])
    fecha.setSeconds(0)
    return fecha
}

const capitalizarObjeto = (objeto) => {
    let objetoResultado = {}
    for (const propiedad in objeto) {
        let propiedadResultado = capitalize(propiedad)
        propiedadResultado = propiedadResultado.replaceAll("_"," ")
        propiedadResultado = insertaAcento(propiedadResultado)
        objetoResultado[propiedadResultado] = objeto[propiedad]
    }
    return objetoResultado
}

function capitalize(palabra) {
    return palabra[0].toUpperCase() + palabra.slice(1).toLowerCase()
}  

const convertirNumeroFechaXls = (fecha) => {
    if(!fecha){
        return ""
    }
    if(typeof fecha === "string"){
        return fecha
    }
    return format(new Date((fecha - 25569) * 86400 * 1000 ), 'yyyy-MM-dd')
}

function insertaAcento(palabra){
    const cadenaArray = palabra.split(' ')
    const cadenaArrayActualizada = []
    cadenaArray.forEach(element => {
        switch (element) {
            case 'Almacen':
                cadenaArrayActualizada.push('Almacén')
                break
            case 'Dia':
                cadenaArrayActualizada.push('Día')
                break
            case 'servicio':
                cadenaArrayActualizada.push('servicio')
                break
            case 'especificas':
                cadenaArrayActualizada.push('específicas')
                break
            default:
                cadenaArrayActualizada.push(element)
                break
        }
    })
    return cadenaArrayActualizada.join(' ').toString()
}

const definirEtiquetaConsecutivo = (consecutivo, existeAlmacenInicio, existeConsecutivoCero) => {
    const consecutivoParseado = parseInt(consecutivo)
    return existeConsecutivoCero
        ? (consecutivoParseado === 0 && existeAlmacenInicio ? 'A' : String(consecutivoParseado + 1 || ''))
        : String(consecutivoParseado || '')
}

const pluck = (arr, key) => arr.map(i => i[key])

const calcularDistanciaEntreDosPuntos = async (coordenadasPuntoUno, coordenadasPuntoDos) => {
    const googleMaps = await googleMapsLoader.load()
    const puntoUno = new googleMaps.maps.LatLng(coordenadasPuntoUno.lat, coordenadasPuntoUno.lng)
    const puntoDos = new googleMaps.maps.LatLng(coordenadasPuntoDos.lat, coordenadasPuntoDos.lng)
    return googleMaps.maps.geometry.spherical.computeDistanceBetween(puntoUno, puntoDos)
}

const descargaBaseSesentaycuatro = (baseSesentaycuatro, nombreDelDocumento, tipo) => {
    const a = document.createElement('a')
    a.href = tipo=='zip'?'data:text/plain;base64,'+baseSesentaycuatro:'data:application/'+tipo+';base64,'+baseSesentaycuatro
    a.download = `${nombreDelDocumento}.${tipo}`
    a.click()
}

const obtenerDiasMes = (fecha, semanas, diasProhibidos) => {
    const diasDeVisitaProhibidos = []
    if(!diasProhibidos || diasProhibidos == null) return diasDeVisitaProhibidos
    const array = []
    const split = diasProhibidos.split(',')
    for (let index = 0; index < (7*semanas); index++) {
        const date = new Date(fecha)
        if(index == 0) date.setDate(date.getDate() + 1)
        else date.setDate(date.getDate() + (index+1))
        array.push(format(date, 'yyyy-MM-dd'))
    }
    split.forEach(dia => {
        const buscaFecha = array.find(fecha => fecha == dia)
        if(buscaFecha) diasDeVisitaProhibidos.push(dia)
    })
    return diasDeVisitaProhibidos
}

const creaObjetoConfiguracionPlaneacion = async (configuracionActiva) => {
    const { configuracion } = configuracionActiva
    const habilitadoUsoGeocercas = esTipoZonaGeocerca()
    let obj = {
        gITCluster:{
            desvalanceArea: parseInt(configuracion.properties.find(porpetie => porpetie.nombre == 'areaImbalance').valor),
            superposicion: configuracion.properties.find(porpetie => porpetie.nombre == 'overlap').valor==0 ? false : true,
            usarZonaGeocerca: (configuracion.properties.find(porpetie => porpetie.nombre == 'useZoneGeofence')?.valor === "1" && habilitadoUsoGeocercas) ?? false,
        },
        gITVisit:{
            desvalanceDiario: parseInt(configuracion.properties.find(porpetie => porpetie.nombre == 'dailyImbalance').valor),
            desvalanceSemanal: parseInt(configuracion.properties.find(porpetie => porpetie.nombre == 'weeklyImbalance').valor)
        },
        workDays: configuracion.properties.find(porpetie => porpetie.nombre == 'workDays').valor.split(','),
        forbiddenDates: configuracion.properties.find(porpetie => porpetie.nombre == 'forbiddenDates').valor,
        automaticAreas: {
            reminder: configuracion.properties.find(porpetie => porpetie.nombre == 'reminder').valor==0 ? false : true,
            averageIntensity: parseInt(configuracion.properties.find(porpetie => porpetie.nombre == 'averageIntensity').valor),
            schedule: {
                mondayToFriday: {
                    start: configuracion.properties.find(porpetie => porpetie.nombre == 'mondayToFridayStart').valor,
                    end: configuracion.properties.find(porpetie => porpetie.nombre == 'mondayToFridayEnd').valor
                },
                saturday: {
                    start: configuracion.properties.find(porpetie => porpetie.nombre == 'saturdayStart').valor,
                    end: configuracion.properties.find(porpetie => porpetie.nombre == 'saturdayEnd').valor
                },
                sunday: {
                    start: configuracion.properties.find(porpetie => porpetie.nombre == 'sundayStart').valor,
                    end: configuracion.properties.find(porpetie => porpetie.nombre == 'sundayEnd').valor
                }
            }
        },
        matrizDistacia:{
            storedProfile: configuracion.properties.find(porpetie => porpetie.nombre == 'storedProfile') ? configuracion.properties.find(porpetie => porpetie.nombre == 'storedProfile').valor : 'car'
        },
        countries: null,
        countryValidation: false
    }

    if (Object.prototype.hasOwnProperty.call(configuracionActiva, 'configuracionCompania')) {
        const countries = configuracionActiva.configuracionCompania.find(porpetie => porpetie.nombre == 'gitlogistics_selected_countries')
        if(countries){
            obj.countries = JSON.parse(countries.valor)
        }
        const countryValidation = configuracionActiva.configuracionCompania.find(porpetie => porpetie.nombre == 'gitlogistics_country_validation')
        if(countryValidation){
            obj.countryValidation = countryValidation.valor==0 ? false : true
        }
    }

    return obj
}

/**
 * Permite ordenar un arreglo de objetos en su plimer nivel de forma acendente o decendente
 * @param {array} - Array de objetos 
 * @param {propiedad} - llave a ordenar
 * @param {orden} - tipo de order ('asc','desc')
*/
const ordenaArrayObject = async (array, propiedad, orden) => {
    array.sort(function(a, b) {
        const propA = typeof a[propiedad] == 'string' ? a[propiedad].toUpperCase() : a[propiedad]
        const propB = typeof b[propiedad] == 'string' ? b[propiedad].toUpperCase() : b[propiedad]
        if (orden == 'asc') {
            if (propA < propB) return -1
            if (propA > propB) return 1
        }else if (orden == 'desc') {
            if (propA > propB) return -1
            if (propA < propB) return 1
        }

        return 0
    })
    
    return array
}

/**
 * Permite descargar un archivo en formato PDF
 * @param {Blob} - Blob a descargar
 * @param {string} - Nombre que llevara el archivo
 */
const descargaPdf = (blob, fieldName) => {
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.href = url
    link.download = `${fieldName}.pdf`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
}

export {
    mostrarPorcentaje, 
    mostrarTiempo, 
    mostrarDistancia,
    agregaMinutos,
    removerElementoArray,
    obtenerFechaDeHora,
    capitalizarObjeto,
    convertirNumeroFechaXls,
    definirEtiquetaConsecutivo,
    pluck,
    formatearDistanciaPlaneada,
    calcularKilometrosEjecutados,
    calcularDistanciaEntreDosPuntos,
    descargaBaseSesentaycuatro,
    obtenerDiasMes,
    creaObjetoConfiguracionPlaneacion,
    ordenaArrayObject,
    compararHoras,
    descargaPdf
}